import React, { useState, useEffect } from "react"
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps"
import * as parkData from "../data/skateboard-parks.json"
import mapStyles from "./mapStyles"

function Map() {
  const [selectedLocation, setSelectedLocation] = useState(null)

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedLocation(null)
      }
    }
    window.addEventListener("keydown", listener)

    return () => {
      window.removeEventListener("keydown", listener)
    }
  }, [])

  return (
    <div>
      <p>Triple Bottom Line</p>
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: 52.4862, lng: -1.8904 }}
        defaultOptions={{ styles: mapStyles }}
      >
        {parkData.experiences.map(location => (
          <Marker
            key={location.properties.LOCATION_ID}
            position={{
              lat: location.geometry.coordinates[1],
              lng: location.geometry.coordinates[0],
            }}
            onClick={() => {
              setSelectedLocation(location)
            }}
            icon={{
              url: `/skateboarding.svg`,
              scaledSize: new window.google.maps.Size(25, 25),
            }}
          />
        ))}

        {selectedLocation && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedLocation(null)
            }}
            position={{
              lat: selectedLocation.geometry.coordinates[1],
              lng: selectedLocation.geometry.coordinates[0],
            }}
          >
            <div>
              <h2>{selectedLocation.properties.PLACE}</h2>
              <p>{selectedLocation.properties.NOTES}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  )
}

const MapWrapped = withScriptjs(withGoogleMap(Map))

export default function App() {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        // googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB98uOCwGSZc3oMqMbyiC1KpfIy5jIMXQA`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  )
}
