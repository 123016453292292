import React from "react"
import scss from "./SocialIcons.scss"
import lnkedin from "./lnkedin.png"
import medium from "./medium.png"
import gthub from "./gthub.png"

function SocialIcons() {
  return (
    <div id="circ">
      <ul>
        <li class="animated zoomIn">
          <div class="back"></div>
          <div class="icon">
            <i class="fa fa-facebook"></i>
            <img alt src={gthub} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/impactyogi"
            />
          </div>
        </li>
        <li class="animated zoomIn delayone">
          <div class="back"></div>
          <div class="icon">
            <i class="fa fa-twitter"></i>
            <img alt src={lnkedin} />
          </div>
        </li>
        <li class="animated zoomIn delaytwo">
          <div class="back"></div>
          <div class="icon">
            <i class="fa fa-linkedin"></i>
            <img alt src={medium} />
          </div>
        </li>
        {/* <li class="animated zoomIn delaythree">
          <div class="back"></div>
          <div class="icon">
            <i class="fa fa-youtube"></i>
          </div>
        </li>
        <li class="animated zoomIn delayfour">
          <div class="back"></div>
          <div class="icon">
            <i class="fa fa-pinterest-p"></i>
          </div>
        </li>
        <li class="animated zoomIn delayfive">
          <div class="back"></div>
          <div class="icon">
            <i class="fa fa-google-plus"></i>
          </div>
        </li> */}
      </ul>
    </div>
  )
}

export default SocialIcons
