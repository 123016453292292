import React from "react"
import css from "./PersonalProjects.module.css"
import Particle from "../Particles/index"
import aquaponics from "../../images/aquaponics.jpg"
import biodome from "../../images/biodome.jpg"
import carpentry from "../../images/carpentry.jpg"
import yoga from "../../images/yoga.jpg"
import running from "../../images/running.jpg"
import butterfly from "../../images/butterfly.jpg"
import trekking from "../../images/trekking.jpg"
import fiddle from "../../images/fiddle.jpg"
import session from "../../images/session.jpg"
import socialimpact from "../../images/socialimpact.jpeg"
import bmx2 from "../../images/bmx2.jpg"
import biofuel from "../../images/biofuel.png"
import solarproject from "../../images/solarproject.png"

function PersonalProjects() {
  return (
    <div className={css.hexagon}>
      <Particle />

      <section>
        <article>
          <figure>
            <h2>Bio Domes</h2>
          </figure>
          <img alt src={biodome} />
        </article>
        <article>
          <figure>
            <h2>Carpentry</h2>
          </figure>
          <img alt src={carpentry} />
        </article>
        <article>
          <figure>
            <h2>Trekking</h2>
          </figure>
          <img alt src={trekking} />
        </article>
        <article>
          <figure>
            <h2>Social Impact</h2>
          </figure>
          <img alt src={socialimpact} />
        </article>
        <article>
          <figure>
            <h2>Music</h2>
          </figure>
          <img alt src={session} />
        </article>
        <article>
          <figure>
            <h2>Swimming</h2>
          </figure>
          <img alt src={butterfly} />
        </article>
        <article>
          <figure>
            <h2>BMX</h2>
          </figure>
          <img alt src={bmx2} />
        </article>
        <article>
          <figure>
            <h2>Running</h2>
          </figure>
          <img alt src={running} />
        </article>
        <article>
          <figure>
            <h2>Bio Fuel</h2>
          </figure>
          <img alt src={biofuel} />
        </article>
        <article>
          <figure>
            <h2>Solar Energy</h2>
          </figure>
          <img alt src={solarproject} />
        </article>
        <article>
          <figure>
            <h2>Yoga/Me-ditation</h2>
          </figure>
          <img alt src={yoga} />
        </article>
        <article>
          <figure>
            <h2>Aqua -ponics</h2>
          </figure>
          <img alt src={aquaponics} />
        </article>
      </section>
      <div className={css.wrapper}>
        <div className={css.item1}></div>
      </div>
      <svg width="0" height="0">
        <defs>
          <clipPath id="hexagono" clipPathUnits="objectBoundingBox">
            <polygon points=".25 0, .75 0, 1 .5, .75 1, .25 1, 0 .5" />
          </clipPath>
        </defs>
      </svg>
      <div className={css.wrapper}>
        <div className={css.item1}>
          <h2>My Story</h2>
        </div>
      </div>
    </div>
  )
}

export default PersonalProjects
