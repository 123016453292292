import React from "react"
import css from "./MyStory.module.css"

function myStory() {
  return (
    <div class="mystory">
      <p>
        I have lived and worked in 6 countries,(UK, SE, FR, IT, ES, GT) and
        though what initially led me there was learning the languages, cultures
        and understanding the people and their ways of working I also learnt a
        lot about analytics, strategy, new markets and business and product
        development, finally finding my real passion in the intersection between
        business and social impact within social enterprise.
      </p>
      <p>
        This led me to question how to increase social, environmental and
        positive economic impact and I became interested in tech for its
        capacity to create scaleable solutions to real world problems which can
        cross physical boundries and continents and require relitively low
        start-up capital in comparison to bricks and mortar businesses or
        products. I am currently working as a software engineer and am learning
        more about all things DevSecOps, Big Data and its visualisation, AWS and
        Security Machine Learning in my free time.
      </p>
      <p>
        I am strongly motivated by sustainability, social equality and the
        environment, I have been involved in biodiesel production, aquaponics,
        CNC machining and biodome construction. I enjoy the outdoors and sport
        and have swum regionally for the UK, run marathons /ultras and done some
        more random things like working as a trapeze artist along the way. I
        practice yoga and meditation and relish challenging my own perspectives
        and capabilities as often as possible, I am presently doing this via
        tech and love building things collaboratively.{" "}
      </p>
      <h3>Let's build something great together!</h3>
    </div>
  )
}

export default myStory
