import React from "react"
import css from "./HexagonProjects.module.css"
import Tetris from "../Projects/Tetris"
import Clock from "../Projects/Clock"
import Battleship from "../Projects/Battleship"
import QuizApp from "../Projects/QuizApp"
import Pokedex from "../Projects/Pokedex"
import Balloon from "../Projects/Balloon"
import Pokemon from "../Projects/Pokemon"
import ToDos from "../Projects/ToDos"
import CRM1 from "../Projects/CRM1"
import CRM2 from "../Projects/CRM2"
import Urtisan from "../Projects/Urtisan"
import MecanoCNC from "../Projects/MecanoCNC"
import Particle from "../Particles/index"
import MenuPopupState from "../Menu/index"
import Circle from "../Circle/index"

function HexagonProject() {
  return (
    <div className={css.hexagon}>
      <div className={css.wrapper}>
        <div className={css.item1}>
          <h2> Tech</h2>
        </div>
      </div>
      <br></br>
      <br></br>

      <section>
        <article>
          <figure>
            <h2>Tetris</h2>
            <Tetris />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1509664158680-07c5032b51e5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Clock</h2>
            <Clock />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1508035460735-91088c495500?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Battleship</h2>
            <Battleship />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1510245987579-6ee8a9041294?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>QuizApp</h2>
            <QuizApp />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1525454240972-e37288888ff0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Pokedex</h2>
            <Pokedex />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1468272705519-72f1d5e4b310?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Balloon</h2>
            <Balloon />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1510034141778-a4d065653d92?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Pokemon</h2>
            <Pokemon />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>To Dos</h2>
            <ToDos />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1504805572947-34fad45aed93?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>CMA</h2>
            <CRM1 />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1552882687-39b9f74cd311?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Meal Things</h2>
            <CRM2 />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1532443859453-b840b8aad16e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Urtisan</h2>
            <Urtisan />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1528543010705-e7e75169b717?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
        <article>
          <figure>
            <h2>Mecano</h2>
            <MecanoCNC />
          </figure>
          <img
            alt
            src="https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
          />
        </article>
      </section>

      {/* <svg width="0" height="0">
        <defs>
          <clipPath id="hexagono" clipPathUnits="objectBoundingBox">
            <polygon points=".25 0, .75 0, 1 .5, .75 1, .25 1, 0 .5" />
          </clipPath>
        </defs>
      </svg> */}

      <div className={css.wrapper}>
        <div className={css.item1}>
          <h2>Personal</h2>
        </div>
      </div>
    </div>
  )
}

export default HexagonProject
