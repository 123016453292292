import React from "react"
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Fab from "@material-ui/core/Fab"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import Zoom from "@material-ui/core/Zoom"
import css from "./ScrollBar.module.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

// import { HashLink as Link } from 'react-router-hash-link';

const handleLink = linkID => {
  const anchor = document.querySelector("#" + linkID)
  console.log("handle link called,", anchor)
  if (anchor) {
    anchor.scrollIntoView({ behavior: "smooth", block: "center" })
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    )

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default function BackToTop(props) {
  return (
    <React.Fragment>
      <AppBar style={{ background: "#001965" }}>
        {/* <Toolbar classes={{ root: css.scrollBarText }}> */}
        <div classname={css.scrollBarText}>
          <p
            className={css.anchorLinks}
            onClick={() => handleLink("techprojects")}
          >
            Tech Projects
          </p>
          <p
            className={css.anchorLinks}
            onClick={() => handleLink("personalprojects")}
          >
            Personal Projects
          </p>
          <p className={css.anchorLinks} onClick={() => handleLink("mystory")}>
            My Story
          </p>
          <p
            className={css.anchorLinks}
            onClick={() => handleLink("collaborate")}
          >
            Collaborate
          </p>

          <p className={css.anchorLinks} onClick={() => handleLink("socials")}>
            Socials
          </p>
        </div>
        {/* </Toolbar> */}
      </AppBar>
      <Toolbar id="back-to-top-anchor" />

      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  )
}
