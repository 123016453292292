import React, { useState, useEffect } from "react";
import css from "./Quote.module.css";

const Quote = () => {
  const [quote, setQuote] = useState("");

  useEffect(() => {
    async function getQuote() {
      const response = await fetch("https://api.kanye.rest");
      const jsonResponse = await response.json();
      setQuote(jsonResponse.quote);
    }
    getQuote();
    const interval = setInterval(() => {
      getQuote();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={css.quote}>
      <q>{quote}</q>
      <br />
      <cite> API of interesting problems that need solving</cite>
    </div>
  );
};

export default Quote;
