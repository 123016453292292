import React from "react"
import { Link, onClick } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HexagonProject from "../components/HexagonProjects"
import Quote from "../components/Quote/index"
import SocialIcons from "../components/SocialIcons/index"
import Map from "../components/Map/index"
import ScrollBar from "../components/ScrollBar/index"
import PersonalProjects from "../components/PersonalProjects/index"
import scss from "../components/layout.scss"
import Particle from "../components/Particles"
import Circle from "../components/Circle"
import NodeMailer from "../components/NodeMailer"
import Particles from "../components/Particles"
import MyStory from "../components/MyStory"

// import MenuPopUpState from "../components/Menu/index"

function IndexPage() {
  return (
    <>
      <Layout>
        <ScrollBar />
        {/* <MenuPopUpState /> */}
        <Particle />
        <Circle />
        <SEO title="Home" />
        {/* If I decide to add social icons */}
        {/* <SocialIcons /> */}

        <div className={scss.sectionDownload} id="techprojects"></div>

        <HexagonProject />
        <div className={scss.sectionDownload} id="personalprojects"></div>
        <PersonalProjects />

        <div className={scss.sectionDownload} id="mystory"></div>
        <MyStory />
        <div className={scss.sectionDownload} id="collaborate"></div>
        <NodeMailer />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        {/* <TestForm /> */}

        <div className={scss.sectionDownload} id="socials"></div>

        {/* If I decide to add an interesting/relevant API */}
        {/* <Quote /> */}
        {/* Commented out to save credit on map */}
        {/* 
        <h3 style={{ textalign: "center" }}>
          Where I'm Going and Where I've Been
        </h3> */}
        {/* <Map /> */}
        <SocialIcons />
      </Layout>
    </>
  )
}

export default IndexPage
